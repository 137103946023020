import { Entity, EntityPolicy } from '@backstage/catalog-model';
import { REPOSITORY_VALIDATIONS_SPEC } from './repository-entity-validations';
import { EntityValidator } from '../../../common';
import { RepositoryAlphaEntity } from '../index';

export class RepositoryEntityPolicy implements EntityPolicy {
  constructor(
    private readonly validator = new EntityValidator<RepositoryAlphaEntity>(
      REPOSITORY_VALIDATIONS_SPEC,
    ),
  ) {
    this.validator = validator;
  }

  async enforce(entity: RepositoryAlphaEntity): Promise<Entity> {
    if (entity.kind === 'Repository') {
      this.validator.applyDefaults(entity);
    }
    return entity;
  }
}
