import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import AutoIcon from '@mui/icons-material/BrightnessAuto';
import React, { cloneElement, ReactElement, useState } from 'react';
import useObservable from 'react-use/esm/useObservable';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

type ThemeIconProps = {
  active?: boolean;
  icon: ReactElement | undefined;
};

const ThemeIcon = ({ active, icon }: ThemeIconProps) =>
  icon ? (
    cloneElement(icon, {
      color: active ? 'primary' : undefined,
    })
  ) : (
    <AutoIcon color={active ? 'primary' : undefined} />
  );

type ThemeSwitcherProps = {
  style?: any;
};

export const ThemeSwitcher = (props: Readonly<ThemeSwitcherProps>) => {
  const { style } = props;
  const appThemeApi = useApi(appThemeApiRef);
  const themeId = useObservable(
    appThemeApi.activeThemeId$(),
    appThemeApi.getActiveThemeId(),
  );
  const themeIds = appThemeApi.getInstalledThemes();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectTheme = (newThemeId: string | undefined) => {
    if (themeIds.some(t => t.id === newThemeId)) {
      appThemeApi.setActiveThemeId(newThemeId);
    } else {
      appThemeApi.setActiveThemeId(undefined);
    }

    setAnchorEl(undefined);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <IconButton
        id="theme-button"
        aria-haspopup="listbox"
        aria-controls="theme-menu"
        aria-label="switch theme"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        {style && <FormatPaintIcon style={style} />}
        {!style && <FormatPaintIcon color="secondary" />}
      </IconButton>

      <Menu
        id="theme-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'theme-button',
          role: 'listbox',
        }}
      >
        <MenuItem disabled>Choose a theme</MenuItem>
        <MenuItem
          selected={themeId === undefined}
          onClick={() => handleSelectTheme(undefined)}
        >
          <ListItemIcon>
            <ThemeIcon icon={undefined} active={themeId === undefined} />
          </ListItemIcon>
          <ListItemText>Auto</ListItemText>
        </MenuItem>

        {themeIds.map(theme => {
          const active = theme.id === themeId;
          return (
            <MenuItem
              key={theme.id}
              selected={active}
              aria-selected={active}
              onClick={() => handleSelectTheme(theme.id)}
            >
              <ListItemIcon>
                <ThemeIcon icon={theme.icon} active={active} />
              </ListItemIcon>
              <ListItemText>{theme.title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
