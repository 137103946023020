import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PlatformFeatureFlag } from '../../../types/platform-feature.flag';

const useStyles = makeStyles()(theme => ({
  danger: {
    color: theme.palette.error.main,
  },
}));

export const FeatureFlagListItem = ({
  featureFlag,
  isForbidden,
}: {
  featureFlag?: PlatformFeatureFlag;
  isForbidden?: boolean;
}) => {
  const { classes } = useStyles();

  return (
    <ListItemText
      primary={
        <>
          {featureFlag?.feature}
          {isForbidden && (
            <Typography
              variant="subtitle2"
              className={classes.danger}
              color="primary"
            >
              <b>You are not allowed to change this setting.</b>
            </Typography>
          )}
          {featureFlag?.owner && (
            <Typography variant="subtitle2" className="font-bold">
              <b>Owned by</b> {featureFlag?.owner}
            </Typography>
          )}
          {featureFlag?.allowedOwners &&
            featureFlag?.allowedOwners?.length > 0 && (
              <Typography variant="subtitle2" className="font-bold">
                <b>Allowed Owners: </b> {featureFlag?.allowedOwners?.join(', ')}
              </Typography>
            )}
          {featureFlag?.forbiddenOwners && (
            <Typography variant="subtitle2" className="font-bold">
              <b>Forbidden Owners: </b>{' '}
              {featureFlag?.forbiddenOwners?.join(',')}
            </Typography>
          )}
        </>
      }
      secondary={featureFlag?.description}
    />
  );
};
