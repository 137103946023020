import { InfoCard } from '@backstage/core-components';
import { useUserIdentity } from '@mb.io/core-components';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import React from 'react';
import {
  usePlatformFeatureFlagContext,
  useUserFeatureFlagContext,
} from '../../context';
import { PlatformFeatureFlagged } from '../PlatformFeatureFlagged';
import { PlatformFeatureFlagItem } from './item-cards/PlatformFeatureFlagItem';
import { UserFeatureFlags } from './item-cards/UserFeatureFlags.tsx';
import { UserFeatureSelectableFlags } from '../SelectableFlags/UserFeatureSelectableFlags.tsx';

export const PlatformFeatureFlagPage = () => {
  const { features } = usePlatformFeatureFlagContext();
  const { features: userFeatures } = useUserFeatureFlagContext();

  const { user, loading } = useUserIdentity(false, true);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Grid container>
      <Grid item md={12} lg={6} className="grid-item">
        <InfoCard
          title="Global Flags"
          subheader="This kind of global features can be enabled or disabled global only. You can not edit global flags since they are not available for custom usage."
        >
          <List dense>
            {features
              .filter(featureFlag => !featureFlag.toggleable)
              .map(featureFlag => (
                <PlatformFeatureFlagItem
                  key={featureFlag.feature}
                  featureFlag={featureFlag}
                />
              ))}
          </List>
        </InfoCard>
      </Grid>
      <Grid item md={12} lg={6} className="grid-item">
        <InfoCard
          title="Toggleable Flags"
          subheader="This kind of global features can be enabled or disabled for the current session. You can enable/disable toggleable flags since they are defined in the configuration to customize for each user."
        >
          <UserFeatureFlags userFeatures={userFeatures} user={user} />
        </InfoCard>
      </Grid>

      <PlatformFeatureFlagged feature="demo">
        <Grid item md={12} lg={6} className="grid-item">
          <InfoCard
            title="Toggleable panel"
            subheader="You can hide or show this panel to see the usage of toggleable panels."
          />
        </Grid>
      </PlatformFeatureFlagged>

      <Grid item md={12} lg={6} className="grid-item">
        <InfoCard
          title="Selectable Flags"
          subheader="This kind of global features can be enabled or disabled for the current session. You can select flags from the dropdown menu since they are defined in the configuration to customize for each user."
        >
          <UserFeatureSelectableFlags userFeatures={userFeatures} user={user} />
        </InfoCard>
      </Grid>
    </Grid>
  );
};
