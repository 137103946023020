import { useEffect, useState } from 'react';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import {
  FEATURE_THEME,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';

export interface ThemePreference {
  theme: string;
}

let initialSyncRef = true;

export function useSyncTheme() {
  const userSettingsApi = useApi(userSettingsApiRef);
  const appThemeApi = useApi(appThemeApiRef);
  const [currentTheme, setCurrentTheme] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const initializeTheme = async (
      activeTheme: string | undefined,
      theme: string | undefined,
    ) => {
      const installedThemes = appThemeApi.getInstalledThemes();

      if (!activeTheme) {
        appThemeApi.setActiveThemeId(installedThemes[0].id);
        setCurrentTheme(installedThemes[0].id);
      }

      if (!theme) {
        // Ensure that the theme is set to auto
        appThemeApi.setActiveThemeId(undefined);
        setCurrentTheme(undefined);
      }
    };

    const syncTheme = async () => {
      const preference = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: FEATURE_THEME,
      });

      const value = preference?.value as unknown as ThemePreference;
      const activeTheme = appThemeApi.getActiveThemeId();

      if (initialSyncRef) {
        initialSyncRef = false;

        if (!activeTheme || !value?.theme) {
          await initializeTheme(activeTheme, value?.theme);
        }

        if (value?.theme && value?.theme !== activeTheme) {
          appThemeApi.setActiveThemeId(value?.theme);
          setCurrentTheme(value?.theme);
        }
      } else if (value?.theme !== activeTheme) {
        // When the user changes the theme, sync the theme
        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: FEATURE_THEME,
          value: {
            theme: activeTheme,
          },
        });
        setCurrentTheme(activeTheme);
      }
    };

    syncTheme();
  }, [appThemeApi, userSettingsApi]);

  return { currentTheme };
}
