import { parseEntityRef } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ContentEntity, ContentType } from '@mb.io/catalog-model';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import useAsync from 'react-use/esm/useAsync';
import yaml from 'yaml';
import { PlatformFeatureFlag } from '../types/platform-feature.flag';
import {
  UserFeatureFlagType,
  useUserFeatureFlagContext,
} from './UserFeatureFlagContext';

export type PlatformFeatureFlagContextType = {
  features: PlatformFeatureFlag[];
  findPlatformFeatureFlag: (feature: string) => PlatformFeatureFlag | undefined;
};

export const PlatformFeatureFlagContext =
  createContext<PlatformFeatureFlagContextType>({
    features: [],
    findPlatformFeatureFlag: () => {
      return {} as PlatformFeatureFlag;
    },
  });

const parseContent = (content: ContentEntity) => {
  let contentArray: PlatformFeatureFlag[] = [];
  if (content?.spec.data) {
    if (content.spec.type === ContentType.JSON) {
      contentArray = JSON.parse(content.spec.data);
    } else if (content.spec.type === ContentType.YAML) {
      try {
        contentArray = yaml.parse(content.spec.data);
      } catch (e) {
        contentArray = [];
      }
    }
  }
  return contentArray;
};

export const PlatformFeatureFlagContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const catalogApi = useApi(catalogApiRef);
  const configApi = useApi(configApiRef);

  const [features, setFeatures] = useState<PlatformFeatureFlag[]>([]);
  const { features: userFeatures, initialize } = useUserFeatureFlagContext();

  const value: PlatformFeatureFlagContextType = useMemo(
    () => ({
      features,
      findPlatformFeatureFlag: feature =>
        features.find(current => current.feature === feature),
    }),
    [features],
  );

  useAsync(async () => {
    const reference = configApi.getOptionalString('app.featureFlags.ref');
    const configFeatures =
      configApi.getOptional<PlatformFeatureFlag[]>(
        'app.featureFlags.features',
      ) ?? [];
    const featureContext: PlatformFeatureFlag[] = configFeatures;

    if (reference) {
      try {
        const entityRef = parseEntityRef(reference);
        const content = (await catalogApi.getEntityByRef(
          entityRef,
        )) as ContentEntity;

        parseContent(content).forEach(resourceBasedFlag => {
          // config features has the highest priority, skip resource based feature if a config flag exists.
          if (
            configFeatures.findIndex(
              ({ feature }) => feature === resourceBasedFlag.feature,
            ) >= 0
          ) {
            return;
          }

          const index = featureContext.findIndex(
            current => current.feature === resourceBasedFlag.feature,
          );
          if (index >= 0) {
            featureContext[index] = resourceBasedFlag;
          } else {
            featureContext.push(resourceBasedFlag);
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    const initialFeatures: UserFeatureFlagType = {};
    featureContext
      .filter(feature => feature.toggleable)
      .forEach(current => {
        initialFeatures[current.feature] =
          userFeatures[current.feature] === undefined
            ? current.defaultToggleValue
            : userFeatures[current.feature];
      });
    initialize(initialFeatures);

    setFeatures(featureContext);
  }, [configApi, catalogApi, setFeatures]);

  return (
    <PlatformFeatureFlagContext.Provider value={value}>
      {children}
    </PlatformFeatureFlagContext.Provider>
  );
};

export function usePlatformFeatureFlagContext(): PlatformFeatureFlagContextType {
  const context = useContext(PlatformFeatureFlagContext);
  if (!context) {
    throw new Error(
      'usePlatformFeatureFlags must be used within GlobalFlagContextType, use <PlatformFeatureFlagContextProvider /> before calling this hook',
    );
  }
  return context;
}
