import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

type PlatformFeatureSelectedFlagContextType = {
  selectedFlag: string;
  setSelectedFlag: Dispatch<React.SetStateAction<string>>;
};

const PlatformFeatureSelectedFlagContext = createContext<
  PlatformFeatureSelectedFlagContextType | undefined
>(undefined);

export const PlatformFeatureSelectedFlagProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedFlag, setSelectedFlag] = useState<string>('');

  const value: PlatformFeatureSelectedFlagContextType = useMemo(
    () => ({
      selectedFlag,
      setSelectedFlag,
    }),
    [selectedFlag],
  );

  return (
    <PlatformFeatureSelectedFlagContext.Provider value={value}>
      {children}
    </PlatformFeatureSelectedFlagContext.Provider>
  );
};

export const usePlatformFeatureSelectedFlagContext = () => {
  const context = useContext(PlatformFeatureSelectedFlagContext);
  if (!context) {
    throw new Error(
      'usePlatformFeatureSelectedFlag must be used within a PlatformFeatureSelectedFlagProvider',
    );
  }
  return context;
};
