import { UserFeatureFlagType } from '../../../context/UserFeatureFlagContext.tsx';
import { UserEntity } from '@backstage/catalog-model';
import { usePlatformFeatureFlagContext } from '../../../context';
import List from '@mui/material/List';
import React from 'react';
import { UserFeatureFlagItem } from './UserFeatureFlagItem.tsx';

type Props = {
  userFeatures: UserFeatureFlagType;
  user?: UserEntity;
};

export const UserFeatureFlags = ({ userFeatures, user }: Props) => {
  const { findPlatformFeatureFlag } = usePlatformFeatureFlagContext();

  return (
    <List dense>
      {Object.keys(userFeatures).map(feature => {
        return (
          !findPlatformFeatureFlag(feature)?.selectable && (
            <UserFeatureFlagItem feature={feature} user={user} key={feature} />
          )
        );
      })}
    </List>
  );
};
