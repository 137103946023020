import { JSONSchema6 } from 'json-schema';
import Schema from './schema/repository.alpha.schema.json';

export { RepositoryEntityPolicy } from './policy/repository-entity.policy';
export type { RepositoryAlphaEntity } from './repository.alpha.entity';
export type { RepositoryAlphaEntity as RepositoryEntity } from './repository.alpha.entity';

export const RepositoryAlphaSchema: JSONSchema6 = Schema as Omit<
  JSONSchema6,
  'examples'
>;
