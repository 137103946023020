import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  actionEnum,
  AssignMember,
  AssignMemberDTO,
  AssignMembers,
  AssignMembersDTO,
  AuditLogsDto,
  AccountRequestDto,
  AwsAccountId,
  AwsAccountProjectDto,
  AwsAccountUser,
  BlueBoxAwsAccountDto,
  BlueBoxValidateSecretDto,
  BlueBoxValidateSecretResponseDto,
  CreateAccess,
  CreateTeamsDto,
  DeleteAccess,
  InstallCatalogPluginDto,
  InstalledPluginDto,
  InstalledPluginsDTO,
  LeanIxTeamDto,
  PluginCheckLogDto,
  PluginsAndPlayListsDto,
  ProjectInfo,
  RemoveTeamDTO,
  SIPCentralApi,
  TeamId,
  TeamsDto,
  ToolDto,
  TransitService,
  UpdateAccess,
  UserDto,
  UserId,
  WiwId,
  WiwIdDto,
} from '@mercedes-benz/sip-central-common';

export const sipCentralApiRef = createApiRef<SIPCentralApi>({
  id: 'plugin.sip-central.api',
});

export type SipCentralRequest = {
  method: string;
  path: string;
  body?: object;
  responseAsJson?: boolean;
  bearerToken?: string;
};

export class SIPCentralApiClient implements SIPCentralApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  getSIPContent(): Promise<any> {
    return this.submitRequest({
      method: 'GET',
      path: `/sip/content`,
    });
  }

  getAccountMembers({ userId }: UserId): Promise<AwsAccountUser[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/users/${encodeURIComponent(userId)}/account-members`,
    });
  }

  getUser({ userId }: UserId): Promise<UserDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/users/${encodeURIComponent(userId)}`,
    });
  }

  getUserForCatalog({ userId }: UserId): Promise<UserDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/user/${encodeURIComponent(userId)}`,
    });
  }

  validateWiwId(wiwId: WiwId): Promise<WiwIdDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/alice/users/${wiwId}`,
    });
  }

  async createTeams(body: CreateTeamsDto): Promise<any> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/teams`,
      body,
    });

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.CREATE_TEAM,
      activities: [
        {
          teamDetails: body.teams.map(team => ({
            currentTeam: {
              name: team.name,
              owner: team.owner,
              manager: team.manager,
            },
          })),
        },
      ],
      actionDate: new Date().toJSON(),
    });

    return res;
  }

  async updateTeams(body: TeamsDto): Promise<TeamsDto> {
    const res = await this.submitRequest({
      method: 'PUT',
      path: `/teams`,
      body,
    });
    if (
      body.teams.filter(
        team => team.oldTeam !== team.name || team.oldManager !== team.manager,
      ).length > 0
    ) {
      this.auditLogs({
        currentUser: undefined,
        action: actionEnum.UPDATE_TEAM,
        activities: [
          {
            teamDetails: body.teams
              .filter(
                team =>
                  team.oldTeam !== team.name ||
                  team.oldManager !== team.manager,
              )
              .map(team => ({
                currentTeam: {
                  name: team.oldTeam,
                  owner: team.owner,
                  manager: team.oldManager,
                },
                updatedTeam: {
                  name: team.name,
                  manager: team.manager,
                },
              })),
          },
        ],
        actionDate: new Date().toJSON(),
      });
    }
    return res;
  }

  async assignMembers(body: AssignMembers): Promise<AssignMembersDTO> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/teams/members`,
      body,
    });

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.ADD_TEAM_MEMBERS,
      activities: body.members.map(member => ({
        performedOn: member.id,
        teamMembers: [
          {
            teamRole: member.teamRole,
            teams: member.teamNames,
          },
        ],
      })),
      actionDate: new Date().toJSON(),
    });

    return res;
  }

  async deleteTeam(
    teamId: string,
    lastTeam?: Boolean,
    teamName?: string,
  ): Promise<RemoveTeamDTO> {
    const path = `/teams/${encodeURIComponent(teamId)}${
      lastTeam ? '?lastTeam=true' : ''
    }`;
    const methodBody = {
      method: 'DELETE',
      path: path,
    };
    const res = this.submitRequest(methodBody);

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.DELETE_TEAM,
      activities: [{ teamDetails: [{ currentTeam: { name: teamName } }] }],
      actionDate: new Date().toJSON(),
      currentTeam: teamName,
    });

    return res;
  }

  async reassignTeamMembers(
    teamId: string,
    body?: AssignMembers,
  ): Promise<RemoveTeamDTO> {
    const path = `/teams/${encodeURIComponent(teamId)}/reassign`;
    const methodBody = {
      method: 'POST',
      path: path,
    };

    if (body !== undefined) {
      const res = await this.submitRequest({ ...methodBody, body });
      this.auditLogs({
        currentUser: undefined,
        action: actionEnum.REASSIGN_USERS,
        activities: body.members
          .filter((member: any) => member.reAssignedTeamNames?.length !== 0)
          .map(member => ({
            performedOn: member.id,
            teamMembers: [{ teams: member.reAssignedTeamNames }],
          })),
        actionDate: new Date().toJSON(),
        currentTeam: body.teamName,
      });
      return res;
    }
    return this.submitRequest(methodBody);
  }

  async assignMember(
    body: AssignMember,
    { teamId }: TeamId,
    teamName?: string,
  ): Promise<AssignMemberDTO> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/teams/${encodeURIComponent(teamId)}/member`,
      body,
    });

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.ADD_USER,
      activities: [
        {
          performedOn: body.id,
          teamMembers: [{ teamRole: body.teamRole }],
        },
      ],
      actionDate: new Date().toJSON(),
      currentTeam: teamName,
    });

    return res;
  }

  async createAccess(body: CreateAccess): Promise<any> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/users`,
      body,
    });

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.GRANT_PERMISSION,
      activities: [
        {
          performedOn: body.id,
          toolPermissions: {
            tool: 'AWS',
            permissions: body.awsRoleRequests.map(ele => ({
              currentRoles: ele.awsSelectedRoles,
              instance: ele.awsInstance,
            })),
          },
        },
      ],
      actionDate: new Date().toJSON(),
    });

    return res;
  }

  getTeams({ userId }: UserId): Promise<TeamsDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/users/${encodeURIComponent(userId)}/teams`,
    });
  }

  getTeam({ teamId }: TeamId, enhanceUserInfo?: Boolean): Promise<any> {
    const queryParm = !!enhanceUserInfo
      ? `?enhanceUserInfo=${enhanceUserInfo}`
      : '';
    return this.submitRequest({
      method: 'GET',
      path: `/teams/${encodeURIComponent(teamId)}${queryParm}`,
    });
  }

  async removeMember({ userId }: UserId, ownerId: string): Promise<any> {
    const res = this.submitRequest({
      method: 'DELETE',
      path: `/teams/users/${encodeURIComponent(
        userId,
      )}?ownerId=${encodeURIComponent(ownerId)}`,
    });

    await this.auditLogs({
      currentUser: undefined,
      action: actionEnum.DELETE_USER,
      activities: [{ performedOn: userId }],
      actionDate: new Date().toJSON(),
    });

    return res;
  }

  async updateAccess(body: UpdateAccess, { userId }: UserId): Promise<any> {
    const res = this.submitRequest({
      method: 'PUT',
      path: `/users/${encodeURIComponent(userId)}`,
      body,
    });
    if (
      body.awsRoleRequests.filter(
        awsRoleRequest => awsRoleRequest.awsExistingRoles?.length !== 0,
      ).length > 0
    ) {
      await this.auditLogs({
        currentUser: undefined,
        action: actionEnum.UPDATE_PERMISSION,
        activities: [
          {
            performedOn: userId,
            toolPermissions: {
              tool: 'AWS',
              permissions: body.awsRoleRequests
                .filter(
                  awsRoleRequest =>
                    awsRoleRequest.awsExistingRoles?.length !== 0,
                )
                .map(ele => ({
                  currentRoles: ele.awsExistingRoles,
                  updatedRoles: ele.awsSelectedRoles,
                  instance: ele.awsInstance,
                })),
            },
          },
        ],
        actionDate: new Date().toJSON(),
      });
    }

    return res;
  }

  async deleteAccess(body: DeleteAccess, { userId }: UserId): Promise<any> {
    const res = await this.submitRequest({
      method: 'POST',
      path: `/users/${encodeURIComponent(userId)}/revoke`,
      body: { awsAccountIds: body.awsAccountIds?.map(ele => ele.awsAccountId) },
    });

    this.auditLogs({
      currentUser: undefined,
      action: actionEnum.REVOKE_PERMISSION,
      activities: [
        {
          performedOn: userId,
          toolPermissions: {
            tool: 'AWS',
            permissions: body.awsAccountIds.map(ele => ({
              instance: ele.awsInstance,
            })),
          },
        },
      ],
      actionDate: new Date().toJSON(),
    });

    return res;
  }

  getTeamsOfOwner({ userId }: UserId): Promise<TeamsDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/teams/${encodeURIComponent(userId)}/get-teams`,
    });
  }

  auditLogs(body: AuditLogsDto): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/audit-logs`,
      body,
    }).catch();
  }

  getPluginListAndPlayList(
    accountId: string,
    userId: string,
  ): Promise<PluginsAndPlayListsDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/plugins/${encodeURIComponent(accountId)}?userId=${encodeURIComponent(userId)}`,
    });
  }

  getPluginLogs(buildId: string): Promise<PluginCheckLogDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/plugin/logs/${encodeURIComponent(buildId)}`,
    });
  }

  getInstalledPluginList(
    accountId: string,
    userId: string,
  ): Promise<InstalledPluginsDTO> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/plugin-request/${encodeURIComponent(accountId)}?userId=${encodeURIComponent(userId)}`,
    });
  }

  getTools(): Promise<ToolDto[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/tools`,
    });
  }

  getPluginConfig(pluginName: string): Promise<string> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/plugin-config/${encodeURIComponent(pluginName)}`,
    });
  }

  getPluginConfigForVersion(
    pluginId: string,
    version: string,
  ): Promise<string> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/plugin-config/${encodeURIComponent(pluginId)}/version?version=${encodeURIComponent(version)}`,
    });
  }

  getBlueBoxAccountDetails({
    awsAccountId,
  }: AwsAccountId): Promise<BlueBoxAwsAccountDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/aws-account/${encodeURIComponent(awsAccountId)}`,
    });
  }

  installCatalogPlugin(body: InstallCatalogPluginDto): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/catalog/plugin/install`,
      body,
    });
  }

  uninstallCatalogPlugin(
    pluginRequestId: string,
    userId: string,
  ): Promise<any> {
    return this.submitRequest({
      method: 'DELETE',
      path: `/catalog/plugin/uninstall/${encodeURIComponent(pluginRequestId)}?userId=${encodeURIComponent(userId)}`,
    });
  }

  updateCatalogPlugin(
    eventId: string,
    buildStatus: string,
    body: InstallCatalogPluginDto,
  ): Promise<any> {
    return this.submitRequest({
      method: 'PUT',
      path: `/catalog/plugin/update-config/${encodeURIComponent(eventId)}
      ?buildStatus=${encodeURIComponent(buildStatus)}`,
      body,
    });
  }

  validateLeanIxId(leanIx: String): Promise<LeanIxTeamDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/leanix/${leanIx}`,
    });
  }

  getPluginRequestForNamespace(
    awsAccount: string,
    pluginName: string,
    namespace: string,
  ): Promise<InstalledPluginDto> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/namespace/${awsAccount}?plugin=${pluginName}&namespace=${namespace}`,
    });
  }

  onboardCloudEngineAccountDetails(
    accountDetails: BlueBoxAwsAccountDto,
  ): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/catalog/aws-account`,
      body: accountDetails,
    });
  }

  updateCloudEngineAccountDetails(
    accountId: string,
    accountDetails: BlueBoxAwsAccountDto,
  ): Promise<any> {
    return this.submitRequest({
      method: 'PUT',
      path: `/catalog/aws-account/${accountId}`,
      body: accountDetails,
    });
  }

  triggerGithubAppSyncJob(currentUser: string): Promise<any> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/triggerGithubAppSyncJob?user=${encodeURIComponent(currentUser)}`,
    });
  }

  getTransitServicesForAccount(accountId: string): Promise<TransitService[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/catalog/transit-services/${accountId}`,
    });
  }

  validateSecret(
    body: BlueBoxValidateSecretDto,
  ): Promise<BlueBoxValidateSecretResponseDto> {
    return this.submitRequest({
      method: 'POST',
      path: `/catalog/plugin/validate-secret`,
      body,
    });
  }

  createAwsAccountAndProject(
    awsAccountProjectDto: AwsAccountProjectDto,
  ): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/account-management/create`,
      body: awsAccountProjectDto,
    });
  }

  getProjectDetails(
    projectId: string,
    bearerToken: string,
  ): Promise<ProjectInfo> {
    return this.submitRequest({
      method: 'GET',
      path: `/account-management/project/${projectId}`,
      bearerToken,
    });
  }

  getAccountRequestsForCurrentUser(
    currentUser: string,
  ): Promise<AccountRequestDto[]> {
    return this.submitRequest({
      method: 'GET',
      path: `/account-management/account-requests?currentUser=${encodeURIComponent(currentUser)}`,
    });
  }

  createAwsAccount(
    awsAccountProjectDto: AwsAccountProjectDto,
    projectId: string,
  ): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/account-management/project/${projectId}/create`,
      body: awsAccountProjectDto,
    });
  }

  private async submitRequest({
    path,
    method,
    body,
    responseAsJson = true,
    bearerToken,
  }: SipCentralRequest): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('sip-central')}${path}`;
    const headers: Record<string, string> = {
      'content-type': 'application/json',
      'Bearer-token': bearerToken || '',
    };

    const response = await this.fetchApi.fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? await response.json() : response;
  }
}
