import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import { useApi } from '@backstage/core-plugin-api';
import {
  FEATURE_USER_ENABLED_FEATURES,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';

// no need to store all other fields in this case.
export type UserFeatureFlagType = Record<string, boolean | undefined>;

type UserFeatureFlagContextType = {
  features: UserFeatureFlagType;
  initialize: (initialFeatures: UserFeatureFlagType) => void;
  update: (feature: string, enabled: boolean) => void;
};

const readFeaturesFromUserSettingsBucket = async (
  userSettingsApi: any,
): Promise<UserFeatureFlagType> => {
  const item = await userSettingsApi.get({
    bucket: USER_PREFERENCES,
    key: FEATURE_USER_ENABLED_FEATURES,
  });
  if (!item?.value) {
    return {};
  }
  return item.value;
};

const UserFeatureFlagContext = createContext<
  UserFeatureFlagContextType | undefined
>(undefined);

export const UserFeatureFlagContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const userSettingsApi = useApi(userSettingsApiRef);
  const [features, setFeatures] = useState<UserFeatureFlagType>({});

  useEffect(() => {
    const fetchInitialFeatures = async () => {
      const initialFeatures =
        await readFeaturesFromUserSettingsBucket(userSettingsApi);
      setFeatures(initialFeatures);
    };

    fetchInitialFeatures();
  }, [userSettingsApi]);

  const value: UserFeatureFlagContextType = useMemo(
    () => ({
      features,
      initialize: async (initialFeatures: UserFeatureFlagType) => {
        const storedFeatures =
          await readFeaturesFromUserSettingsBucket(userSettingsApi);
        const updatedFeatures = { ...storedFeatures };

        // Add or update keys from initialFeatures
        for (const key of Object.keys(initialFeatures)) {
          if (!(key in storedFeatures)) {
            updatedFeatures[key] = initialFeatures[key];
          } else if (storedFeatures[key] !== initialFeatures[key]) {
            updatedFeatures[key] = storedFeatures[key];
          }
        }

        // Remove keys that are no longer in initialFeatures
        for (const key of Object.keys(storedFeatures)) {
          if (!(key in initialFeatures)) {
            delete updatedFeatures[key];
          }
        }

        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: FEATURE_USER_ENABLED_FEATURES,
          value: updatedFeatures,
        });
        setFeatures(updatedFeatures);
      },
      update: async (feature: string, enabled: boolean) => {
        const updatedFeatures = { ...features, [feature]: enabled };
        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: FEATURE_USER_ENABLED_FEATURES,
          value: updatedFeatures,
        });
        setFeatures(updatedFeatures);
      },
    }),
    [features, setFeatures, userSettingsApi],
  );

  return (
    <UserFeatureFlagContext.Provider value={value}>
      {children}
    </UserFeatureFlagContext.Provider>
  );
};

export function useUserFeatureFlagContext(): UserFeatureFlagContextType {
  const context = useContext(UserFeatureFlagContext);
  if (!context) {
    throw new Error(
      'useUserFeatureFlagContext must be used within GlobalFlagContextType, use <UserFeatureFlagContext /> before calling this hook',
    );
  }
  return context;
}
