import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { DEFAULT_NAMESPACE, Entity } from '@backstage/catalog-model';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  EntityRefLink,
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { getComparator } from '../../utils';
import { TagsCollection } from '../TagsCollection/TagsCollection.tsx';

export interface TableData {
  name: string;
  owner: string;
  description?: string;
  type?: string;
  tags?: string[];
  targets?: string;
}

export type EntityTableProps = {
  entities: Entity[];
  headCells: HeadCell[];
};

const allowedRowsPerPage = [5, 10, 20, 50];
type Order = 'asc' | 'desc';

export interface HeadCell {
  id: keyof TableData;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof TableData,
  ) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: keyof TableData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            sx={{ pointerEvents: 'none' }}
            icon={<StarIcon />}
          />
        </TableCell>
        {headCells?.map(headCell => (
          <TableCell
            key={headCell.id}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function EntityTable({
  entities,
  headCells,
}: Readonly<EntityTableProps>) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>('name');
  const [page, setPage] = useState(0);
  const [formattedEntities, setFormattedEntities] = useState<any>([]);
  const { toggleStarredEntity, isStarredEntity } = useStarredEntities();
  const { limit: rowsPerPage, setLimit } = useEntityList();

  useEffect(() => {
    // When entities change, reset the page to 0
    setPage(0);
  }, [entities, setPage]);

  useEffect(() => {
    const processedEntities = entities?.map((item: Entity) => ({
      kind: item?.kind ?? 'Component',
      namespace: item?.metadata.namespace ?? 'default',
      name: item.metadata.title ?? item?.metadata.name,
      description: item?.metadata.description ?? '',
      tags: item.metadata.tags ?? [],
      owner: item.spec?.owner ?? '',
      targets: item.spec?.targets ?? '',
      type: item.spec?.type ?? '',
      uri: `/catalog/${item.metadata.namespace ?? DEFAULT_NAMESPACE}/${item.kind}/${item.metadata.name}`,
    }));

    setFormattedEntities(processedEntities);
  }, [entities, setFormattedEntities]);

  const handleRequestSort = (
    _: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSizeSelection = parseInt(event.target.value, 10);
    setLimit(pageSizeSelection);
  };

  const getEntityToBeStarred = (element: any) => {
    return entities.find(
      item =>
        item.metadata?.title === element.name ||
        item.metadata.name === element.name,
    );
  };

  const isEntityStarred = (element: any) => {
    const entityToBeStarred = getEntityToBeStarred(element);
    return entityToBeStarred ? isStarredEntity(entityToBeStarred) : false;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - formattedEntities.length)
      : 0;

  const humanizeOwnerName = (owner: string) => {
    return owner.split(':').pop()?.split('/').pop() ?? '';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750, tableLayout: 'fixed' }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {formattedEntities
                  ?.sort(getComparator(order, orderBy))
                  .slice()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entity: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={entity.title || index}
                        sx={{ height: 'auto' }}
                      >
                        <TableCell
                          padding="checkbox"
                          key={`${entity.title || index}-checkbox`}
                        >
                          <Checkbox
                            icon={<StarBorderIcon />}
                            checkedIcon={<StarIcon />}
                            color="primary"
                            data-testid="favourites-button"
                            checked={isEntityStarred(entity)}
                            onChange={() => {
                              const entityToBeStarred =
                                getEntityToBeStarred(entity);
                              if (entityToBeStarred) {
                                toggleStarredEntity(entityToBeStarred);
                              }
                            }}
                          />
                        </TableCell>
                        {headCells.some(headCell => headCell.id === 'name') &&
                          entity.name && (
                            <TableCell
                              key={`${entity.title || index}-name`}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Button
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '700',
                                  justifyContent: 'flex-start',
                                  ml: 1,
                                  '&:hover': {
                                    color: 'inherit',
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline',
                                  },
                                }}
                                component={Link}
                                to={entity.uri}
                              >
                                {entity.name}
                              </Button>
                            </TableCell>
                          )}
                        {headCells.some(headCell => headCell.id === 'owner') &&
                          entity.owner && (
                            <TableCell
                              key={`${entity.title || index}-owner`}
                              sx={{ paddingLeft: '0' }}
                            >
                              <EntityRefLink
                                entityRef={{
                                  kind: 'group',
                                  namespace: 'default',
                                  name: humanizeOwnerName(entity?.owner),
                                }}
                              />
                            </TableCell>
                          )}
                        {headCells.some(headCell => headCell.id === 'type') && (
                          <TableCell
                            key={`${entity.title || index}-type`}
                            sx={{ paddingLeft: '0' }}
                          >
                            {entity.type}
                          </TableCell>
                        )}
                        {headCells.some(
                          headCell => headCell.id === 'targets',
                        ) && (
                          <TableCell
                            key={`${entity.title || index}-targets`}
                            sx={{
                              paddingLeft: '0',
                              whiteSpace: 'normal',
                              maxWidth: '50%',
                              wordBreak: 'break-word',
                            }}
                          >
                            {entity.targets}
                          </TableCell>
                        )}
                        {headCells.some(
                          headCell => headCell.id === 'description',
                        ) && (
                          <TableCell
                            key={`${entity.title || index}-description`}
                            sx={{
                              paddingLeft: '0',
                              whiteSpace: 'normal',
                              maxWidth: '50%',
                              wordBreak: 'break-word',
                            }}
                          >
                            {entity.description}
                          </TableCell>
                        )}
                        {headCells.some(headCell => headCell.id === 'tags') && (
                          <TableCell
                            key={`${entity.title || index}-tags`}
                            sx={{ paddingLeft: '0' }}
                          >
                            <TagsCollection
                              tags={entity.tags}
                              maxVisibleTags={2}
                              chipSize="small"
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow sx={{ height: 'auto' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={allowedRowsPerPage}
            component="div"
            count={entities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
