import { UserEntity } from '@backstage/catalog-model';
import { useUserGroups } from '@mb.io/core-components';
import ListItem from '@mui/material/ListItem';
import React, { useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import {
  FEATURE_USER_SELECTABLE_OPTIONS,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';
import { FeatureFlagListItem } from '../page/item-cards/FeatureFlagListItem.tsx';
import {
  usePlatformFeatureFlagContext,
  usePlatformFeatureSelectedFlagContext,
} from '../../context';

type Props = {
  feature: string;
  user?: UserEntity;
};

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
    marginRight: '24px',
    borderRadius: '2px',
    borderBottom:
      theme.palette.mode === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.70)'
        : '1px solid rgba(0, 0, 0, 0.60)',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(26, 26, 26, 0.92)'
        : theme.palette.background.default,
  },
}));

export const UserFeatureSelectableFlagItem = ({ feature, user }: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const userSettingsApi = useApi(userSettingsApiRef);
  const userGroupNames = useUserGroups(user);
  const { findPlatformFeatureFlag } = usePlatformFeatureFlagContext();
  const platformFeatureFlag = findPlatformFeatureFlag(feature);
  const { selectedFlag, setSelectedFlag } =
    usePlatformFeatureSelectedFlagContext();

  let isForbidden: boolean | undefined = false;

  if (platformFeatureFlag?.allowedOwners?.length && !user) {
    isForbidden = true;
  } else if (typeof platformFeatureFlag?.allowedOwners !== 'undefined') {
    isForbidden =
      userGroupNames.findIndex(groupName =>
        platformFeatureFlag.allowedOwners?.includes(groupName),
      ) === -1;
  }

  if (!isForbidden && platformFeatureFlag) {
    isForbidden =
      platformFeatureFlag.forbiddenOwners &&
      userGroupNames.findIndex(groupName =>
        platformFeatureFlag.forbiddenOwners?.includes(groupName),
      )! >= 0;
  }

  useEffect(() => {
    const fetchFeature = async () => {
      const settings = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: FEATURE_USER_SELECTABLE_OPTIONS,
      });

      const value = settings?.value || '';

      setSelectedFlag(
        typeof value === 'string'
          ? value
          : platformFeatureFlag?.defaultSelectedValue ?? '',
      );
    };

    fetchFeature();
  }, [userSettingsApi, setSelectedFlag, platformFeatureFlag]);

  const handleSelectChange = async (event: SelectChangeEvent) => {
    const value = event.target.value;

    await userSettingsApi.set({
      bucket: USER_PREFERENCES,
      key: FEATURE_USER_SELECTABLE_OPTIONS,
      value: value,
    });

    setSelectedFlag(value);
  };

  useEffect(() => {
    if (!selectedFlag) {
      setSelectedFlag(platformFeatureFlag?.defaultSelectedValue ?? '');
    }
  }, [setSelectedFlag, platformFeatureFlag, selectedFlag]);

  const validSelectedFlag = platformFeatureFlag?.availableOptions?.includes(
    selectedFlag,
  )
    ? selectedFlag
    : platformFeatureFlag?.defaultSelectedValue ?? '';

  return (
    <ListItem divider data-testid="user-feature-selectable-item">
      {platformFeatureFlag?.selectable ? (
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <FormControl variant="filled" sx={{ width: '200px' }} size="small">
            <InputLabel id="user-feature-select-label">Options</InputLabel>
            <Select
              data-testid="user-feature-select"
              value={validSelectedFlag}
              className={classes.select}
              onChange={handleSelectChange}
              disabled={isForbidden}
            >
              {platformFeatureFlag.availableOptions?.map(option => {
                return (
                  <MenuItem key={option} value={option}>
                    {' '}
                    {option}{' '}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FeatureFlagListItem
            featureFlag={platformFeatureFlag}
            isForbidden={isForbidden}
          />
        </Box>
      ) : null}
    </ListItem>
  );
};
