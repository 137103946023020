import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  createSearchResultListItemExtension,
  SearchResultListItemExtensionProps,
} from '@backstage/plugin-search-react';

import {
  esgCategoryRouteRef,
  esgDocsRouteRef,
  esgHomeRouteRef,
  esgStaticDocRouteRef,
} from './routes';
import { GuidelinesSearchResultListItemProps } from './search/GuidelinesSearchResultListItem';

export const esgPlugin = createPlugin({
  id: 'esg',
  routes: {
    root: esgHomeRouteRef,
    category: esgCategoryRouteRef,
    esgDocs: esgDocsRouteRef,
    staticDoc: esgStaticDocRouteRef,
  },
});

/**
 * Adds an ESG route for the TechDocs page
 *
 * Pro Tip: In Backstage Route definitions we can not use the Wrapper directly without providing it in techdocs plugin routes.
 */
export const EsgReaderPage = esgPlugin.provide(
  createRoutableExtension({
    name: 'EsgReaderPage',
    component: () => import('./pages/EsgReaderPage').then(m => m.EsgReaderPage),
    mountPoint: esgPlugin.routes.esgDocs,
  }),
);

export const EsgHomePage = esgPlugin.provide(
  createRoutableExtension({
    name: 'EcosystemGuidelinesPage',
    component: () => import('./pages/EsgHomePage').then(m => m.EsgHomePage),
    mountPoint: esgHomeRouteRef,
  }),
);

export const EsgCategoryPage = esgPlugin.provide(
  createRoutableExtension({
    name: 'EsgCategoryPage',
    component: () =>
      import('./pages/EsgCategoryPage').then(m => m.EsgCategoryPage),
    mountPoint: esgCategoryRouteRef,
  }),
);

export const EsgSearchResultListItem: (
  props: SearchResultListItemExtensionProps<GuidelinesSearchResultListItemProps>,
) => JSX.Element | null = esgPlugin.provide(
  createSearchResultListItemExtension({
    name: 'GuidelinesSearchResultListItem',
    component: () =>
      import('./search/GuidelinesSearchResultListItem').then(
        m => m.GuidelinesSearchResultListItem,
      ),
    predicate: result =>
      result.type === 'techdocs' && (result.document as any).guideline,
  }),
);
