import React, { ReactNode, useEffect, useState } from 'react';
import {
  usePlatformFeatureFlagContext,
  usePlatformFeatureSelectedFlagContext,
} from '../../context';
import {
  FEATURE_USER_SELECTABLE_OPTIONS,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';
import { useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';

type UserFeatureFlagProps = {
  feature: string;
  option: string;
  children: ReactNode;
};

export const PlatformFeatureSelectionFlagged = ({
  feature,
  option,
  children,
}: UserFeatureFlagProps) => {
  const { findPlatformFeatureFlag } = usePlatformFeatureFlagContext();
  const { selectedFlag, setSelectedFlag } =
    usePlatformFeatureSelectedFlagContext();
  const featureFlag = findPlatformFeatureFlag(feature);
  const [isFeatureDisplayed, setIsFeatureDisplayed] = useState<boolean>(false);
  const userSettingsApi = useApi(userSettingsApiRef);

  useEffect(() => {
    const fetchFeature = async () => {
      const settings = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: FEATURE_USER_SELECTABLE_OPTIONS,
      });

      const value = settings?.value || '';
      if (value && typeof value === 'string') {
        setSelectedFlag(value);
      } else {
        setSelectedFlag(featureFlag?.defaultSelectedValue ?? '');
      }
    };

    fetchFeature();
  }, [userSettingsApi, featureFlag?.defaultSelectedValue, setSelectedFlag]);

  useEffect(() => {
    const isDisplayed =
      option === selectedFlag &&
      featureFlag?.availableOptions?.includes(selectedFlag);
    setIsFeatureDisplayed(isDisplayed ?? false);
  }, [setIsFeatureDisplayed, selectedFlag, featureFlag, option]);

  return isFeatureDisplayed ? <>{children}</> : <></>;
};
