import { Theme, ThemeOptions } from '@mui/material/styles';
import { AppTheme } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  black,
  blue,
  DarkTheme,
  green,
  grey,
  red,
  white,
  yellow,
} from '@mercedes-benz/mui5-theme';
import React, { ReactElement } from 'react';
import { MBStarIcon } from '../icons';
import {
  BACKGROUND_HOVER_DARK,
  MB_PRIMARY_DARK,
  mbuiBaseTheme,
} from './MBuiBaseTheme';
import { ExtendedOverrides } from './custom-theme-overrides';

const dark = DarkTheme;

const additionalPalette: Partial<Theme['palette']> = {
  background: {
    ...dark.palette.background,
    default: black,
    paper: grey[10],
  },
  text: {
    ...dark.palette.text,
    disabled: grey[70],
    primary: white,
    secondary: grey[70],
  },
  // table header
  textSubtle: white,
  textContrast: white,
  action: {
    ...dark.palette.action,
    active: white,
  },
  primary: {
    ...dark.palette.primary,
    main: white,
  },
  divider: grey[70],
  infoBackground: grey[10],
};

const graphiQLFix = {
  '& [class*=graphiql-container]': {
    color: white,
    // cursor color
    '& .CodeMirror .CodeMirror-cursor': {
      borderLeft: `1px solid ${white}`,
    },
    // selected
    [`& .CodeMirror-focused .CodeMirror-selected,
            .CodeMirror-line::selection,
            .CodeMirror-line > span::selection,
            .CodeMirror-line > span > span::selection`]: {
      background: grey[30],
    },
    [`& .CodeMirror-line::-moz-selection,
        .CodeMirror-line > span::-moz-selection,
        .CodeMirror-line > span > span::-moz-selection`]: {
      background: grey[30],
    },
    // selected but not focused
    [`& .CodeMirror-selected`]: {
      background: grey[35],
    },
    '& .topBar': {
      background: `linear-gradient(${grey[20]}, ${grey[25]})`,
    },
    '& button.docExplorerHide': {
      color: white,
    },
    '& .historyPaneWrap': {
      background: grey[25],
      '& .history-contents': {
        color: white,
        background: 'inherit',
      },
    },
    '& .editorBar': {
      '& .editor-drag-bar': {
        background: grey[20],
      },
      '& .CodeMirror': {
        background: grey[20],
        '& div.CodeMirror-code .cm-comment': {
          color: white,
        },
        '& .CodeMirror-gutters': {
          background: grey[20],
        },
        '& .cm-variable': {
          color: green[70],
        },
        '& .cm-punctuation': {
          color: white,
        },
        '& .cm-def': {
          color: red[50],
        },
        '& .cm-property': {
          color: blue[70],
        },
        '& .cm-string': {
          color: grey[70],
        },
        '& .cm-attribute': {
          color: red[70],
        },
      },
      '& .queryWrap .secondary-editor-title.variable-editor-title': {
        background: grey[20],
        '& .variable-editor-title-text.active': {
          color: white,
        },
        '& .variable-editor-title-text:not(.active)': {
          color: grey[60],
        },
      },
    },
    '& .docExplorerWrap > section': {
      background: grey[25],
      '& .doc-explorer-back': {
        color: grey[90],
        '&::before': {
          borderLeft: `2px solid ${grey[80]}`,
          borderTop: `2px solid ${grey[80]}`,
        },
      },
      '& .doc-explorer-contents': {
        background: grey[25],
        '& input': {
          color: white,
          backgroundColor: grey[25],
          '&::placeholder': {
            color: grey[60],
            opacity: 1,
          },
        },
        '& div.doc-category > div.doc-category-title': {
          color: white,
        },
      },
    },
    '& .keyword': {
      color: green[70],
    },
    '& .type-name': {
      color: yellow[65],
    },
    '& .field-name': {
      color: blue[70],
    },
    '& .arg-name': {
      color: red[70],
    },
    // hover
    [`& .execute-options > li.selected, .toolbar-menu-items > li.hover, .toolbar-menu-items > li:active, .toolbar-menu-items > li:hover, .toolbar-select-options > li.hover, .toolbar-select-options > li:active, .toolbar-select-options > li:hover, .history-contents > li:hover, .history-contents > li:active`]:
      {
        background: BACKGROUND_HOVER_DARK,
        color: white,
      },
  },
};

const additionalOverrides: ThemeOptions['components'] & ExtendedOverrides = {
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: black,
        color: white,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: MB_PRIMARY_DARK,
        marginTop: '2px',
      },
      outlined: {
        color: white,
        backgroundColor: grey[30],
      },
      colorPrimary: {
        backgroundColor: MB_PRIMARY_DARK,
        color: white,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[10],
      },
      notchedOutline: {
        borderColor: grey[70],
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: '#9ecbef',
      },
      barColorPrimary: {
        backgroundColor: MB_PRIMARY_DARK,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      body: {
        '&:hover': {
          backgroundColor: grey[30],
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        color: white,
        '&.Mui-selected': {
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: `${grey[30]} !important`,
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: grey[30],
        },
      },
    },
  },
  BackstageWarningPanel: {
    styleOverrides: {
      panel: {
        backgroundColor: grey[15],
        '& .MuiListItemText-primary': {
          color: white,
          fontWeight: 'bold',
        },
        '& .MuiListItemText-secondary': {
          color: white,
        },
      },
      details: {
        backgroundColor: grey[15],
        color: white,
        // copy-button on the above error-description
        '& li > .MuiIconButton-root': {
          color: white,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
        // copy-button on the 'Full Error as JSON'
        '& div > .MuiIconButton-root': {
          color: grey[80],
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
      summaryText: {
        color: red[45],
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: blue[70],
        '&:hover': {
          color: 'inherit',
        },
      },
    },
  },
  BackstageIconLinkVertical: {
    styleOverrides: {
      primary: {
        color: white,
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        color: white,
        '&.MuiChip-clickable': {
          '& .MuiChip-icon': {
            color: grey[10],
          },
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: { ...graphiQLFix },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: white,
        '&:hover': {
          color: grey[50],
          backgroundColor: 'none',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      ...(dark.components?.MuiSelect as any),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'unset',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
};

export const mbuiDarkTheme = mbuiBaseTheme({
  baseTheme: DarkTheme,
  additionalPalette,
  additionalOverrides,
  type: 'dark',
});

// recursively updates DUI-Theme's primary color

const Provider = ({ children }: { children: ReactElement }) => (
  <UnifiedThemeProvider theme={mbuiDarkTheme}>{children}</UnifiedThemeProvider>
);

export const mbuiDarkThemeWithProvider: Partial<AppTheme> &
  Omit<AppTheme, 'theme'> = {
  id: 'backstage-theme-dark',
  title: 'Dark Theme',
  variant: 'dark',
  icon: (
    <MBStarIcon
      style={{
        width: '22.62px',
        height: '22.62px',
        backgroundColor: black,
        borderRadius: '16px',
      }}
    />
  ),
  Provider,
};
