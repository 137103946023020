import { RepositoryAlphaEntity } from '../index';
import {
  EntityValidation,
  isValidUrl,
  typeValidationMessage,
} from '../../../common';

export const REPOSITORY_VALIDATIONS_SPEC: EntityValidation<RepositoryAlphaEntity>[] =
  [
    {
      field: 'spec.type',
      message: (entity: RepositoryAlphaEntity) =>
        `Type '${entity.spec.type}' is not supported for Repository kind ${typeValidationMessage(entity)}.`,
      validate: (entity: RepositoryAlphaEntity) =>
        ['github'].includes(entity.spec.type),
    },
    {
      field: 'spec.lifecycle',
      message: entity =>
        `Lifecycle '${entity.spec.lifecycle}' is not supported for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity =>
        ['experimental', 'production', 'deprecated'].includes(
          entity.spec.lifecycle,
        ),
    },
    {
      field: 'spec.owner',
      message: entity =>
        `Attribute owner is required for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity => !!entity.spec.owner,
    },
    {
      field: 'spec.system',
      message: entity =>
        `Attribute system is required for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity => !!entity.spec.system,
    },
    {
      field: 'spec.fullName',
      message: entity =>
        `Attribute fullName is required for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity => !!entity.spec.fullName,
    },
    {
      field: 'spec.defaultBranch',
      message: entity =>
        `Attribute defaultBranch is required for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity => !!entity.spec.defaultBranch,
    },
    {
      field: 'spec.organizationName',
      message: entity =>
        `Attribute organizationName is required for Repository kind ${typeValidationMessage(entity)}.`,
      validate: entity => !!entity.spec.organizationName,
    },
    {
      field: 'spec.htmlUrl',
      message: entity =>
        `Attribute htmlUrl should be a valid URL ${typeValidationMessage(entity)}.`,
      validate: entity => isValidUrl(entity.spec.htmlUrl),
    },
  ];
