import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'aiecosystem',
});

export const personalServicesRouteRef = createSubRouteRef({
  id: 'aiecosystem:services',
  parent: rootRouteRef,
  path: '/services',
});
