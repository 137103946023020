import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

export interface TagsCollectionProps {
  tags: string[];
  maxVisibleTags: number;
  onTagClicked?: (tag: string) => void;
  chipSize?: 'small' | 'medium';
}

export function TagsCollection(props: Readonly<TagsCollectionProps>) {
  const { tags, maxVisibleTags, onTagClicked, chipSize = 'medium' } = props;
  const isChipClickable = !!onTagClicked;

  const renderClickableChip = (tag: string) => (
    <Chip
      key={tag}
      label={tag}
      size={chipSize}
      variant="outlined"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        if (isChipClickable) {
          onTagClicked(tag);
        }
      }}
      data-testid="tag-item"
    />
  );

  const renderChip = (tag: string) => (
    <Chip
      key={tag}
      label={tag}
      size={chipSize}
      variant="outlined"
      data-testid="tag-item"
    />
  );

  return (
    <span>
      {tags?.slice(0, maxVisibleTags).map((tag: string) =>
        isChipClickable ? (
          <Tooltip title={`Search Backstage for ${tag}`} key={tag}>
            {renderClickableChip(tag)}
          </Tooltip>
        ) : (
          renderChip(tag)
        ),
      )}
      {tags && tags?.length > maxVisibleTags && (
        <Tooltip
          title={tags?.slice(maxVisibleTags).join('\n')}
          key="show-more-tags"
          componentsProps={{
            tooltip: {
              sx: {
                whiteSpace: 'pre-line',
                fontSize: '14px',
              },
            },
          }}
        >
          <Chip
            label={`+${tags?.length - maxVisibleTags}`}
            size={chipSize ?? 'medium'}
            variant="outlined"
            data-testid="show-more-tags-item"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        </Tooltip>
      )}
    </span>
  );
}
