import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef, personalServicesRouteRef } from './routes';
import { aiEcosystemApiRef, AiEcosystemClient } from './api';

export const aiecosystemPlugin = createPlugin({
  id: 'aiecosystem',
  apis: [
    createApiFactory({
      api: aiEcosystemApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new AiEcosystemClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
    services: personalServicesRouteRef,
  },
});

export const AiEcosystemPage = aiecosystemPlugin.provide(
  createRoutableExtension({
    name: 'AiEcosystemPage',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
