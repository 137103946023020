import { UserFeatureFlagType } from '../../context/UserFeatureFlagContext.tsx';
import { UserFeatureSelectableFlagItem } from './UserFeatureSelectableFlagItem.tsx';
import React from 'react';
import { UserEntity } from '@backstage/catalog-model';
import List from '@mui/material/List';
import { usePlatformFeatureFlagContext } from '../../context';

type Props = {
  userFeatures: UserFeatureFlagType;
  user?: UserEntity;
};

export const UserFeatureSelectableFlags = ({ userFeatures, user }: Props) => {
  const { findPlatformFeatureFlag } = usePlatformFeatureFlagContext();

  return (
    <List dense>
      {Object.keys(userFeatures).map(feature => {
        return (
          findPlatformFeatureFlag(feature)?.selectable && (
            <UserFeatureSelectableFlagItem
              feature={feature}
              user={user}
              key={feature}
            />
          )
        );
      })}
    </List>
  );
};
