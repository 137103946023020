import React, { useEffect, useState } from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';
import Box from '@mui/material/Box';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import Typography from '@mui/material/Typography';
import { useSidebarPinState } from '@backstage/core-components';

export const ThemeSwitcherSidebar = () => {
  const appThemeApi = useApi(appThemeApiRef);
  const [currentTheme, setCurrentTheme] = useState<string | undefined>('');
  const { isPinned } = useSidebarPinState();

  useEffect(() => {
    setCurrentTheme(appThemeApi.getActiveThemeId());
  }, [appThemeApi]);

  const renderMiniThemeSwitcher = () => {
    return (
      <Box
        data-testid="theme-switch-sidebar-mini"
        sx={{ display: 'flex', alignSelf: 'center' }}
      >
        <ThemeSwitcher style={{ color: 'white' }} />
      </Box>
    );
  };

  const renderThemeSwitcher = () => {
    return (
      <Box
        data-testid="theme-switch-sidebar"
        sx={{
          display: 'flex',
          height: '48px',
          gap: '8px',
          alignItems: 'center',
          justifyItems: 'center',
          width: '100%',
        }}
      >
        {currentTheme === 'backstage-theme-light' ? (
          <LightModeIcon
            sx={{ width: '72px', marginRight: '-24px', color: 'white' }}
          />
        ) : (
          <DarkModeIcon sx={{ width: '72px', marginRight: '-24px' }} />
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {currentTheme === 'backstage-theme-light'
              ? 'Light Theme'
              : 'Dark Theme'}
          </Typography>
          <ThemeSwitcher style={{ color: 'white' }} />
        </Box>
      </Box>
    );
  };

  return isPinned ? renderThemeSwitcher() : renderMiniThemeSwitcher();
};
