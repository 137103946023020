import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export const aiEcosystemApiRef = createApiRef<AiEcosystemApi>({
  id: 'plugin.aiecosystem.api',
});

/**
 * Public functions for interacting with the Harbor API.
 *
 */
export interface AiEcosystemApi {
  getPreSignedUrl(userId: string): Promise<any>;
}

export type Request = {
  method: string;
  path: string;
  responseAsJson?: boolean;
  body?: string;
};

export class AiEcosystemClient implements AiEcosystemApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }

  async getPreSignedUrl(userId: string): Promise<any> {
    return this.submitRequest({
      method: 'POST',
      path: `/create-sagemaker-presigned-url-nM7obtF8893ob9R3/api/aiecosystem`,
      responseAsJson: true,
      body: JSON.stringify({
        user_id: userId,
      }),
    });
  }

  private async submitRequest({
    path,
    method,
    responseAsJson = true,
    body,
  }: Request): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('aiecosystem')}${path}`;

    const headers: Record<string, string> = {};

    const response = await this.fetchApi.fetch(url, {
      method,
      headers,
      body: body,
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return responseAsJson ? response.json() : response;
  }
}
