import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { blue, grey } from '@mercedes-benz/mui5-theme';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { DefaultGrid } from '../DefaultGrid';

type TabT = {
  label: string;
  content: any;
  rightSideContent?: React.ReactNode;
  topContent?: React.ReactNode; // Add topContent property
};

type Props = {
  localStorageKey: string;
  tabs: TabT[];
  handleTabChange?: () => void;
  leadingElement?: React.ReactNode;
};

const useStyles = makeStyles<{ isMediumScreen: boolean }>()(
  (theme, { isMediumScreen }) => ({
    container: {
      width: '100%',
    },
    navContainer: {
      paddingBottom: theme.spacing(1),
    },
    tabsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      maxWidth: isMediumScreen ? '100%' : 'unset',
      flexBasis: isMediumScreen ? '100%' : 'unset',
    },
    tabs: {
      flexGrow: 1,
      '& [class*=PrivateTabIndicator-root]': {
        height: '3px',
      },
      // show button for left-scroll even if it is disabled
      '& div[class*=MuiTabScrollButton][class$=Mui-disabled]': {
        opacity: 1,
        // lighten svg-color to visualize that it is disabled
        '& svg': {
          fill: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    tab: {
      '&[class*=MuiTab-root]': {
        minWidth: '75px',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        '&:hover': {
          color: grey[90],
          backgroundColor: blue[40],
        },
      },
    },
  }),
);

export function DefaultTabs({
  localStorageKey,
  tabs,
  handleTabChange,
  leadingElement,
}: Props) {
  const isMediumScreen = useMediaQuery('(max-width:1100px)');
  const { classes } = useStyles({ isMediumScreen });
  const [tabIndex, setTabIndex] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (tabIndex === null) {
      // load from localStorage
      const storedTabIndex = Number(localStorage.getItem(localStorageKey));
      if (
        storedTabIndex === null ||
        isNaN(Number(storedTabIndex)) ||
        storedTabIndex >= tabs.length
      ) {
        setTabIndex(0);
        return;
      }
      setTabIndex(storedTabIndex);
      return;
    }
    if (tabIndex >= tabs.length) {
      setTabIndex(0);
      localStorage.setItem(localStorageKey, '0');
      return;
    }
    // persist in localStorage
    localStorage.setItem(localStorageKey, tabIndex.toString());
  }, [localStorageKey, tabIndex, tabs]);

  const handleChange = (_: any, newIndex: number) => {
    setTabIndex(newIndex);
    if (handleTabChange) {
      handleTabChange();
    }
  };

  if (!Number.isFinite(tabIndex) || tabIndex === null) {
    return <Typography>Loading ...</Typography>;
  }
  if (tabs.length === 0) {
    return (
      <Box className={classes.container}>
        <Box component="nav" className={classes.navContainer}>
          <DefaultGrid variant="container">
            <DefaultGrid variant="item" className={classes.tabsContainer}>
              {leadingElement}
            </DefaultGrid>
          </DefaultGrid>
        </Box>
        <Typography>No program data found ...</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {tabs[tabIndex]?.topContent && (
        <Box className={classes.navContainer}>{tabs[tabIndex].topContent}</Box>
      )}
      <Box component="nav" className={classes.navContainer}>
        <DefaultGrid variant="container">
          <DefaultGrid variant="item" className={classes.tabsContainer}>
            {leadingElement}
            <Tabs
              className={classes.tabs}
              value={tabIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tabs"
              role="tablist"
            >
              {tabs.map(tab => (
                <Tab
                  key={tab.label}
                  className={classes.tab}
                  label={tab.label.toUpperCase()}
                />
              ))}
            </Tabs>
          </DefaultGrid>
          <DefaultGrid variant="item">
            {tabs[tabIndex]?.rightSideContent}
          </DefaultGrid>
        </DefaultGrid>
      </Box>
      {tabs[tabIndex]?.content}
    </Box>
  );
}
